<template>
  <!-- 登录弹窗 -->
  <div class="stars ope" @mouseleave="mouseleave" v-if="type=='ope'">
    <div class="star" :class="index<=num?'active':''" v-for="index in 5" @mouseover="num = index,clickStar=false" @click="clickStarOpe"></div>
  </div>
  <div class="stars show" v-else-if="type=='show'">
    <div class="star" :class="index<=parseInt(scoreNum)?'active':''" v-for="index in 5" :key="index"></div>
  </div>
</template>

<script>
export default {
  name: 'Star',
  data(){
    return {
      num: 0,
      clickStar: false //是否有点击星星
    }
  },
  props: {
    type: String,
    scoreNum: String | Number
  },
  mounted(){
    
  },
  methods: {
  //点击星星
   clickStarOpe(){
     this.clickStar = true;
     this.$emit('returnNum', this.num);
   },
   mouseleave(){
     if(!this.clickStar){
       this.num = 0;
     }
   }
  }
}
</script>

<style scoped lang="scss">
//评分
.stars{
  display: flex;
  align-items: center;
  .star{
    width: 24px;
    height: 24px;
    background: url(~@/assets/star-grey-ico.png) no-repeat;
    background-size: 100%;
    margin-right: 12px;
    &.active{
      background: url(~@/assets/star-yellow-ico.png) no-repeat;
      background-size: 100%;
    }
  }
  &.ope{
    .star{
      cursor: pointer;
    }
  }
}
</style>
